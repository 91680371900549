import React from 'react'
import {
  Breadcrumbs,
  Hero,
  Optimization,
  Page,
  ServiceContact,
} from '@components'

const meta = {
  title: 'Optimizacija troškova protupožarne zaštite | FSB d.o.o.',
  description: 'Projekte analiziramo s multidisciplinarnim timom i poznavanjem široke paleta mogućih tehničkih rješenja, uz suglasnost svih sudionika u gradnji, odabiremo optimalno cjenovno rješenje.',
  image: require('@assets/images/opti.jpg'),
}

const Homepage = () => (
  <Page
    isHomepage
    meta={meta}
  >
    <Breadcrumbs
      links={[
        { link: '/', name: 'Početna'},
        { link: '/usluge', name: 'Usluge'},
        { link: '/usluge/optimizacija-troskova-protupozarne-zastite', name: 'Optimizacija troškova protupožarne zaštite'},
      ]}
    />
    <Hero
      title="Usluga"
      subtitle="Optimizacija troškova protupožarne zaštite"
      description={meta.description}
    />
    <Optimization />
    <ServiceContact id="optimizacija" />
  </Page>
)

export default Homepage